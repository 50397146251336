export const routesAdmin = [
  {
    path: "/admin",
    meta: { title: "경민대학교 입시사이트", requiresAuth: true },
    component: () => import("@/pages/admin2/AdminLayout.vue"),
    children: [
      {
        path: "/admin",
        component: () => import("@/pages/admin2/AdminHomePage.vue"),
      },
      {
        path: "/admin/user",
        component: () => import("@/pages/admin2/AdminUserListPage.vue"),
      },
      {
        path: "/admin/user/create",
        component: () => import("@/pages/admin2/AdminUserCreatePage.vue"),
      },
      {
        path: "/admin/user/:userId/update",
        component: () => import("@/pages/admin2/AdminUserUpdatePage.vue"),
      },
      {
        path: "/admin/banner",
        component: () => import("@/pages/admin2/AdminBannerListPage.vue"),
      },
      {
        path: "/admin/banner/create",
        component: () => import("@/pages/admin2/AdminBannerCreatePage.vue"),
      },
      {
        path: "/admin/banner/:bannerId/update",
        component: () => import("@/pages/admin2/AdminBannerUpdatePage.vue"),
      },
      {
        path: "/admin/recruit",
        component: () => import("@/pages/admin2/AdminRecruitListPage.vue"),
      },
      {
        path: "/admin/recruit/:recruitScheduleId/update",
        component: () => import("@/pages/admin2/AdminRecruitUpdatePage.vue"),
      },
      {
        path: "/admin/file",
        component: () => import("@/pages/admin2/AdminFileListPage.vue"),
      },
      {
        path: "/admin/file/:mainCategoryId/update",
        component: () => import("@/pages/admin2/AdminFileUpdatePage.vue"),
      },
      {
        path: "/admin/notice",
        component: () => import("@/pages/admin2/AdminNoticeListPage.vue"),
      },
      {
        path: "/admin/notice/create",
        component: () => import("@/pages/admin2/AdminNoticeCreatePage.vue"),
      },
      {
        path: "/admin/notice/:noticeId",
        component: () => import("@/pages/admin2/AdminNoticeDetailPage.vue"),
      },
      {
        path: "/admin/notice/:noticeId/update",
        component: () => import("@/pages/admin2/AdminNoticeUpdatePage.vue"),
      },
      {
        path: "/admin/library",
        component: () => import("@/pages/admin2/AdminLibraryListPage.vue"),
      },
      {
        path: "/admin/library/create",
        component: () => import("@/pages/admin2/AdminLibraryCreatePage.vue"),
      },
      {
        path: "/admin/library/:libraryId",
        component: () => import("@/pages/admin2/AdminLibraryDetailPage.vue"),
      },
      {
        path: "/admin/library/:libraryId/update",
        component: () => import("@/pages/admin2/AdminLibraryUpdatePage.vue"),
      },
      {
        path: "/admin/department/:departmentId/banner",
        component: () =>
          import("@/pages/admin2/AdminDepartmentBannerListPage.vue"),
      },
      {
        path: "/admin/department/:departmentId/banner/create",
        component: () =>
          import("@/pages/admin2/AdminDepartmentBannerCreatePage.vue"),
      },
      {
        path: "/admin/department/:departmentId/banner/:bannerId/update",
        component: () =>
          import("@/pages/admin2/AdminDepartmentBannerUpdatePage.vue"),
      },
      {
        path: "/admin/department/:departmentId/professor",
        component: () =>
          import("@/pages/admin2/AdminDepartmentProfessorListPage.vue"),
      },
      {
        path: "/admin/department/:departmentId/professor/create",
        component: () =>
          import("@/pages/admin2/AdminDepartmentProfessorCreatePage.vue"),
      },
      {
        path: "/admin/department/:departmentId/professor/:professorId/update",
        component: () =>
          import("@/pages/admin2/AdminDepartmentProfessorUpdatePage.vue"),
      },
      {
        path: "/admin/department/:departmentId/graduate",
        component: () =>
          import("@/pages/admin2/AdminDepartmentGraduateListPage.vue"),
      },
      {
        path: "/admin/department/:departmentId/graduate/create",
        component: () =>
          import("@/pages/admin2/AdminDepartmentGraduateCreatePage.vue"),
      },
      {
        path: "/admin/department/:departmentId/graduate/:graduateId/update",
        component: () =>
          import("@/pages/admin2/AdminDepartmentGraduateUpdatePage.vue"),
      },
      {
        path: "/admin/department/:departmentId/promotion",
        component: () =>
          import("@/pages/admin2/AdminDepartmentPromotionListPage.vue"),
      },
      {
        path: "/admin/department/:departmentId/promotion/create",
        component: () =>
          import("@/pages/admin2/AdminDepartmentPromotionCreatePage.vue"),
      },
      {
        path: "/admin/department/:departmentId/promotion/:departmentPromotionId/update",
        component: () =>
          import("@/pages/admin2/AdminDepartmentPromotionUpdatePage.vue"),
      },
      {
        path: "/admin/department/:departmentId/media",
        component: () =>
          import("@/pages/admin2/AdminDepartmentMediaListPage.vue"),
      },
      {
        path: "/admin/department/:departmentId/media/create",
        component: () =>
          import("@/pages/admin2/AdminDepartmentMediaCreatePage.vue"),
      },
      {
        path: "/admin/department/:departmentId/media/:departmentMediaId/update",
        component: () =>
          import("@/pages/admin2/AdminDepartmentMediaUpdatePage.vue"),
      },
      {
        path: "/admin/department/:departmentId/news",
        component: () =>
          import("@/pages/admin2/AdminDepartmentNewsListPage.vue"),
      },
      {
        path: "/admin/department/:departmentId/news/create",
        component: () =>
          import("@/pages/admin2/AdminDepartmentNewsCreatePage.vue"),
      },
      {
        path: "/admin/department/:departmentId/news/:newsId",
        component: () =>
          import("@/pages/admin2/AdminDepartmentNewsDetailPage.vue"),
      },
      {
        path: "/admin/department/:departmentId/news/:newsId/update",
        component: () =>
          import("@/pages/admin2/AdminDepartmentNewsUpdatePage.vue"),
      },
      {
        path: "/admin/department/:departmentId/activity",
        component: () =>
          import("@/pages/admin2/AdminDepartmentActivityListPage.vue"),
      },
      {
        path: "/admin/department/:departmentId/activity/create",
        component: () =>
          import("@/pages/admin2/AdminDepartmentActivityCreatePage.vue"),
      },
      {
        path: "/admin/department/:departmentId/activity/:activityId",
        component: () =>
          import("@/pages/admin2/AdminDepartmentActivityDetailPage.vue"),
      },
      {
        path: "/admin/department/:departmentId/activity/:activityId/update",
        component: () =>
          import("@/pages/admin2/AdminDepartmentActivityUpdatePage.vue"),
      },
    ],
  },
  {
    path: "/admin/400",
    component: () => import("@/pages/admin2/AdminErrorPage.vue"),
  },
  {
    path: "/admin/404",
    component: () => import("@/pages/admin2/AdminNotFoundPage.vue"),
  },
  {
    path: "/admin/:pathMatch(.*)*",
    redirect: "/admin/404",
  },
];
